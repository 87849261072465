@tailwind base;
@tailwind components;
@tailwind utilities;


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiDataGrid-toolbarContainer > button{
  color: #000!important;
}
.MuiDataGrid-toolbarContainer > .ButtonMillerTextWhite{
  background-color: #262a73;
  color: white!important;
}

.MuiDataGrid-toolbarContainer > .ButtonMillerError{

  color: white!important;
}
.MuiDataGrid-root {
  border: None !important;
}

.MuiDataGrid-main {
  border-left: 1px solid rgba(224, 224, 224, 1) !important;
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.MuiDataGrid-footerContainer {
  border-left: 1px solid rgba(224, 224, 224, 1) !important;
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.MuiDataGrid-columnHeaders {
  background-color: #262A73;
  color: #fff;

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.MuiDataGrid-columnSeparator{
  color:#262A73!important;
}

.MuiDataGrid-columnHeaderTitle{
  font-weight: bold!important;
}

.MuiDataGrid-menuIcon > .MuiButtonBase-root >.MuiSvgIcon-fontSizeSmall{
  color: #ffffff!important;
}

.MuiDataGrid-iconButtonContainer >.MuiButtonBase-root >.MuiSvgIcon-fontSizeSmall{
  color: #ffffff!important;
}

.MuiSwitch-track{

background-color: #262A73!important;

}

.MuiSwitch-thumb {

  color:#262A73!important;
}

.MuiCircularProgress-root{
  color:#262A73!important;
}

.MuiBadge-badge{
  background-color:#262A73!important;
}
.MuiDataGrid-pinnedColumnHeaders {
  background-color: #262A73 !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.custom-tooltip {
  background: white;
}

.label {
  background: #262A73;
}
